import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  TablePagination,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import styles from "./styles.module.scss";
import {
  getExistLicense,
  getLicense,
  getLicenseShow,
} from "../../../api/candidate/candidate.class";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import UpdateLicense from "../profile/Modal/licenses/UpdateLicense";
import UpdateVisaLicense from "../profile/Modal/licenses/UpdateVisaLicense";
import UpdateBackyCheck from "../profile/Modal/licenses/UpdateBackyCheck";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    width: "90px",
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "10px",
    background: "rgb(0 107 207)",
    color: "white",
  },
}));

const LicenseCandidate = ({ isBackgroundCheck }) => {
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [showVisa, setShowVisa] = useState(false);
  const [showBackyCheck, setShowBackyCheck] = useState(false);
  const [updation, setUpdation] = useState(false);
  const [userDetailData, setUserDetailData] = useState([]);
  const [dataRow, setDataRow] = useState();
  const [existData, setExistData] = useState();
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [licensesResponse, setLicenseResponse] = useState(null);

  useEffect(() => {
    const API = async () => {
      try {
        const respone = await getLicenseShow();
        setUserDetailData(respone?.data?.results);
      } catch (error) {
        console.log(error);
      }
    };
    API();
  }, [updation]);

  useEffect(() => {
    setIsLoading(true);
    const API = async () => {
      try {
        const response = await getLicense(page, isBackgroundCheck).then(
          function (res) {
            return res.data.results;
          }
        );

        setData(response.data);
        setLicenseResponse(response);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };

    API();
  }, [updation, page, isBackgroundCheck]);

  const handleOnClick = async (item) => {
    if (item?.is_visa) {
      setShowVisa(true);
    } else if (item?.is_backy_check) {
      setShowBackyCheck(true);
    } else {
      setShow(true);
    }
    try {
      const response = await getExistLicense({
        ind_cert_lic_request_id: item?.industry_certification_license?.id,
        rec_ind_request_id: item?.recruiter_industry?.id,
      });
      setExistData(response?.data?.results);
    } catch (error) {
      console.log(error);
    }
    console.log("existData", existData);

    setDataRow(item);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };
  return (
    <>
      <Box className={styles.parent}>
        <Typography variant="h4">
          {isBackgroundCheck
            ? "Background Check Requests"
            : "Certificates and Licenses"}
        </Typography>
        <Box className={styles.customBox}>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow
                  sx={{ "& td, & th": { border: 0 } }}
                  className={styles.headingBox}
                >
                  <TableCell align="center">Recruiter Name</TableCell>
                  <TableCell align="center">Company Name</TableCell>
                  <TableCell align="center">Industry License</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading && (
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={5}
                      key={1}
                      sx={{ "& td, & th": { border: 0 } }}
                      className={styles.contentBox}
                    >
                      <>
                        <CircularProgress />
                      </>
                    </TableCell>
                  </TableRow>
                )}
                {!isLoading &&
                  data?.map((row, i) => (
                    <TableRow
                      key={i}
                      sx={{ "& td, & th": { border: 0 } }}
                      className={styles.contentBox}
                    >
                      <TableCell align="center">
                        {row?.recruiter_name}
                      </TableCell>
                      <TableCell align="center">{row?.company_name}</TableCell>
                      <TableCell align="center">
                        {row?.industry_certification_license?.name}
                      </TableCell>
                      <TableCell align="center">
                        <StyledBadge
                          badgeContent={
                            row?.candidate_response === "pending"
                              ? "Pending"
                              : row?.candidate_response === "completed"
                              ? "Completed"
                              : ""
                          }
                        />
                      </TableCell>
                      <TableCell align="center">
                        {row?.candidate_response === "completed" ? (
                          <Button
                            disabled={true}
                            variant="contained"
                            style={{
                              backgroundColor: "#00CFC5",
                              color: "#FFFFFF",
                              border: "none",
                            }}
                            onClick={() => {
                              setShow(true);
                              setDataRow(row);
                            }}
                          >
                            Updated
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            style={{
                              backgroundColor: "#00CFC5",
                              color: "#FFFFFF",
                              border: "none",
                            }}
                            onClick={() => handleOnClick(row)}
                          >
                            Apply For The Check
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="Box"
            count={licensesResponse?.total || 0}
            page={licensesResponse?.current_page - 1 || 0}
            onPageChange={handleChangePage}
            rowsPerPage={licensesResponse?.per_page || 15}
            rowsPerPageOptions={[]}
          />
        </Box>
        {dataRow && (
          <UpdateLicense
            openModal={show}
            setOpenModal={setShow}
            userDetailData={userDetailData}
            type={"request"}
            existData={existData}
            data={dataRow}
            setUpdation={setUpdation}
          />
        )}
        {dataRow && (
          <UpdateVisaLicense
            openModal={showVisa}
            setOpenModal={setShowVisa}
            type={"request"}
            data={dataRow}
            setUpdation={setUpdation}
          />
        )}
        {dataRow && (
          <UpdateBackyCheck
            openModal={showBackyCheck}
            setOpenModal={setShowBackyCheck}
            type={"request"}
            data={dataRow}
            setUpdation={setUpdation}
          />
        )}
      </Box>
    </>
  );
};

export default LicenseCandidate;
