import React, { useState } from "react";
import { ReactComponent as Cross } from "../../../../../assets/industries/model/x.svg";
import styles from "./styles.module.scss";
import { Button, Grid, Box, Modal, Typography } from "@mui/material";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import moment from "moment/moment";
import { updateProfileLicense } from "../../../../../api/candidate/candidate.class";
import CustomSelect from "../../../../common/Select";
import { useDispatch, useSelector } from "react-redux";
import { ShowAlert } from "../../../../../store/actions/alertActions";
import Alerts from "../../../../common/Alert";
import TextInput from "../../../../common/TextInput";
import CountrySelect from "../../../../common/CountrySelect/CountrySelect";
import LicenseCertificateCommonForm from "./LicenseCertificateCommonForm";

export default function UpdateVisaLicense({
  openModal,
  setOpenModal,
  setUpdation,
  userDetailData,
  data,
  type,
}) {
  const handleClose = () => setOpenModal(false);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state?.auth?.results);
  const [file, setFile] = useState({
    front_image: data?.front_image,
    back_image: data?.back_image,
  });
  const [edit1, setEdit1] = useState(true);
  const [edit2, setEdit2] = useState(true);
  const [loading, setLoading] = useState(false);

  const initialValues = {
    recruit_industry:
      {
        value: data?.recruiter_industry?.id,
        label: data?.recruiter_industry?.name,
      } || "",
    industry_cert_license:
      {
        value: data?.industry_certification_license?.id,
        label: data?.industry_certification_license?.name,
      } || "",
    doc_id: data?.document_number || "",
    first_name: auth?.first_name,
    middle_name: auth?.middle_name,
    last_name: auth?.last_name,
    dob: data?.dob ? moment(data?.dob).format("yyyy-MM-DD") : "",
    mail_id: auth?.email,
    country: data?.country || "",
    issue_date: data?.issue_date
      ? new Date(data?.issue_date).toLocaleDateString("en-GB")
      : "",
    expiry_date: data?.expiry_date
      ? new Date(data?.expiry_date).toLocaleDateString("en-GB")
      : "",
  };

  const licenseSchema = Yup.object().shape({
    recruit_industry: Yup.object().required("Enter Recruit Industry"),
    industry_cert_license: Yup.object().required("Enter Certificate License"),
    doc_id: Yup.string().required("Enter Documnet ID"),
    first_name: Yup.string().required("Enter First Name"),
    last_name: Yup.string().required("Enter Last Name"),
    dob: Yup.date()
      .max(new Date(), "Date of Birth must be less than today date")
      .required("Enter Date of Birth"),
    mail_id: Yup.string().required("Enter Email"),
    country: Yup.string().required("Enter Country"),
    issue_date: Yup.string()
      .test("required_if", "Issue date is required", function (value) {
        return value && value.trim() !== "";
      })
      .test(
        "futureDate",
        "Issue date cannot be in the future",
        function (value) {
          const selectedDate = moment(value, "DD/MM/YYYY");
          const currentDate = moment();
          return !selectedDate.isBefore(currentDate);
        }
      ),
    expiry_date: Yup.string().test(
      "required_if",
      "Expiry date is required",
      function (value) {
        return value && value.trim() !== "";
      }
    ),
  });

  const onSubmit = async (values) => {
    let payload;
    setLoading(true);
    try {
      var issueDate =
        values.issue_date && values.issue_date.trim() !== ""
          ? values.issue_date
          : "";
      var expiryDate =
        values.expiry_date && values.expiry_date.trim() !== ""
          ? values.expiry_date
          : "";
      payload = {
        uuid: data?.uuid,
        candidate_id: auth?.candidate_id,
        workspace_id: data?.workspace_id,
        recruiter_id: data?.recruiter_id,
        doc_id: values.doc_id,
        first_name: auth?.first_name,
        last_name: auth?.last_name,
        dob: values?.dob,
        country: values?.country,
        mail_id: auth?.email,
        request_type: "candidate",
        candidate_lic_cert_id: userDetailData?.id || "",
        issue_date: issueDate,
        expiry_date: expiryDate,
      };
      payload["recruitment_industries[" + data.id + "][document_number]"] =
        values.doc_id || "";
      payload["recruitment_industries[" + data.id + "][rec_ind_request_id]"] =
        values.recruit_industry?.value || "";
      payload[
        "recruitment_industries[" + data.id + "][ind_cert_lic_request_id]"
      ] = values.industry_cert_license?.value || "";
      payload["recruitment_industries[" + data.id + "][first_name]"] =
        values.first_name || "";
      payload["recruitment_industries[" + data.id + "][middle_name]"] =
        values.middle_name || "";
      payload["recruitment_industries[" + data.id + "][last_name]"] =
        values.last_name || "";
      payload["recruitment_industries[" + data.id + "][dob]"] =
        values.dob || "";
      payload["recruitment_industries[" + data.id + "][country]"] =
        values.country || "";
      payload["recruitment_industries[" + data.id + "][mail_id]"] =
        values.mail_id || "";
      payload["recruitment_industries[" + data.id + "][issue_date]"] =
        issueDate || "";
      payload["recruitment_industries[" + data.id + "][expiry_date]"] =
        expiryDate || "";

      payload["recruitment_industries[" + data.id + "][front_image]"] =
        file?.front_image;
      payload["recruitment_industries[" + data.id + "][back_image]"] =
        file?.back_image;

      const response = await updateProfileLicense({ ...payload });

      if (response.data.success) {
        setLoading(false);
        dispatch(ShowAlert(response?.data?.message, "success"));
        handleClose();
        setUpdation((old) => old + 1);
      } else if (response.data.success === false) {
        console.log("come to message 12");
        const errorMessages = Object.values(response.data.message).flat();
        errorMessages.forEach((message) => {
          console.log("come to message", message);

          dispatch(ShowAlert(message, "error"));
        });
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleKeyDown = (event) => {
    event.preventDefault(); // Prevents typing
  };

  return (
    <>
      <Alerts />
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={`${styles.industryModel}`}
      >
        <Box
          className={`${styles.boxModel}`}
          style={{ height: "85vh", overflow: "auto" }}
        >
          <Box className={styles.modalwapper}>
            <Box className={styles.cross} onClick={handleClose}>
              <Cross />
            </Box>
            <Typography
              variant="h4"
              className={styles.heading}
              style={{ textTransform: "capitalize" }}
            >
              {data?.is_visa
                ? "Visa Certificates and Licenses"
                : `${data?.industry_certification_license?.name}`}
            </Typography>
            {data?.is_visa && (
              <Typography
                variant="h6"
                style={{ fontSize: "1.2rem", textAlign: "center" }}
              >
                Passport Details
              </Typography>
            )}
            <Formik
              initialValues={initialValues}
              validationSchema={licenseSchema}
              onSubmit={onSubmit}
            >
              {({ values, errors, touched, handleChange, handleSubmit }) => (
                <form onSubmit={handleSubmit} className={styles.formsBox}>
                  <Box className={styles.statusbox}>
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      <LicenseCertificateCommonForm
                        handleChange={handleChange}
                        values={values}
                        errors={errors}
                        touched={touched}
                        file={file}
                        data={data}
                        edit1={edit1}
                        setEdit1={setEdit1}
                        setFile={setFile}
                        edit2={edit2}
                        setEdit2={setEdit2}
                      />

                      {/* <Grid item xs={6}>
                        <Box className={styles.parentInput}>
                          <CustomSelect
                            customClass={styles.select}
                            type="text"
                            name="recruit_industry"
                            value={values.recruit_industry}
                            sx={{ maxWidth: "initial" }}
                            label={
                              <>
                                Recruitment Industries&nbsp;
                                <span style={{ color: "red" }}>*</span>
                              </>
                            }
                            placeholder={"Recruitment Industries"}
                            isDisabled={true}
                          />
                          <span>
                            {errors.recruit_industry &&
                              touched.recruit_industry &&
                              errors.recruit_industry}
                          </span>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box className={styles.parentInput}>
                          <CustomSelect
                            customClass={styles.select}
                            type="text"
                            name="industry_cert_license"
                            value={values.industry_cert_license}
                            placeholder={"Industry Certification License"}
                            sx={{ maxWidth: "initial" }}
                            label={
                              <>
                                Industry Certification License&nbsp;
                                <span style={{ color: "red" }}>*</span>
                              </>
                            }
                            isDisabled={true}
                          />
                          <span>
                            {errors.industry_cert_license &&
                              touched.industry_cert_license &&
                              errors.industry_cert_license}
                          </span>
                        </Box>
                      </Grid> */}
                    </Grid>

                    <Box className={`${styles.button} button-primary`}>
                      <Button type="submit" disabled={loading}>
                        {" "}
                        {loading ? (
                          <p style={{ color: "white" }}>Loading...</p>
                        ) : (
                          "Save"
                        )}
                      </Button>
                      <Button className="secondary-btn" onClick={handleClose}>
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
