import CryptoJS from "crypto-js";
import * as flatted from "flatted";
import React, { useEffect, useState } from "react";
// import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-responsive-modal";
import { useNavigate, useParams } from "react-router-dom";
import S3FileUpload from "react-s3";
// import { toast, ToastContainer } from "react-toastify";
// import useLocalStorage from "use-local-storage";
// import "react-toastify/dist/ReactToastify.css";
import "../../assets/sinner_css/DocSign.css";
// import { useMetamask } from 'use-metamask';

import LoaderGif from "../../assets/common/loader150.gif";
import { setReceiverMetadata } from "../../store/actions/receiverMetadata.action";
// import { getAxiosWithToken } from '../../services/common.services';
// import { URL_PATH } from '../../services/constants';
// import {
//   getDocMetadataFromUrlBlockChain,
//   updateSignerService,
// } from '../../services/docSign.services';
// import { KEY_TOKEN, KEY_USER } from '../../utils/constants';
import { EDH } from "../../utils/EDH";
import s3Config, { getFile, uploadFile } from "../../utils/s3Config";
import PopupModal from "../../components/document_sinner/PopupModal.jsx";
import SignDocContainer from "../../components/document_sinner/SignDocContainer.jsx";
import AskPassword from "../../components/document_sinner/AskPassword.jsx";
// import DashboardHeader from '../common/DashboardHeader';
import MessageBox from "../../components/document_sinner/MessageBox.jsx";
// import useMetamaskEncryptionDecryption from '../hooks/useMetamaskEncryptionDecryption';
import SpinnerLoader from "../../components/document_sinner/SpinnerLoader.jsx";
import {
  EDocView,
  UpdateEDoc,
  UpdateGuestEDoc,
  getGuestDoc,
} from "../../api/sinner/SinnerApi.js";
import { routes } from "../../routes.js";
import { ShowAlert } from "../../store/actions/alertActions.js";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import { FieldTypes } from "../../store/constants/constants.js";
/**
 *
 *
 */

function DocSign() {
  const { id } = useParams();
  const states = useParams();
  const [message, setMessage] = useState(null);
  const [decryptionPopup, setdecryptionPopup] = useState(false);
  const [docInfo, setDocInfo] = useState(null);
  const [decPassword, setDecPassword] = useState("");
  const [passError, setPassError] = useState(null);
  const [metadataString, setMetadataString] = useState(null);

  // const { connect, metaState } = useMetamask();
  const [openNoEtheriumPopup, setOpenNoEtheriumPopup] = useState(false);
  const dispatch = useDispatch();
  const receiverMetadata = useSelector(
    (state) => state.receiverMetadataReducer
  );
  const [signer, setSigner] = useState(null);
  const [s3MetadataUrl, setS3MetadataUrl] = useState("");
  const navigation = useNavigate();
  const [successModalPopup, setSuccessModalPopup] = useState(false);
  const [count, setCount] = useState(0);
  const [showLoader, setShowLoader] = useState({
    show: false,
    message: "Processing...",
  });

  let user = JSON.parse(localStorage.getItem("auth"))?.results || {};
  if (states?.email) {
    user = { email: states?.email };
  }


  useEffect(()=>{
    if(docInfo && !docInfo.is_password_required && decPassword){
      // setDecPassword(process.env.REACT_APP_DEFAULT_PASSWORD)
      setdecryptionPopup(false);
      onPassSubmit() 
    }
  }, [docInfo, decPassword]);
  
  useEffect(() => {
    (async () => {
      try {
        setShowLoader({ show: true, message: "Processing..." });

        setMessage(null);
        let doc;
        if (!states?.email) {
          const query = `recruiter_id=${
            user?.recruiter_id || ""
          }&workspace_id=${user?.workspace_id || ""}&email=${
            user?.email || ""
          }&candidate_id=${user?.candidate_id || ""}`;

          doc = await EDocView(query, id);
        } else {
          doc = await getGuestDoc(id, states?.email);
        }

        if (doc.data.code === 404) {
          setMessage("Requested page is Invalid or Expired");
          dispatch(ShowAlert(doc?.data?.message, "error"));
          return;
        } else if (doc.data.code === 200) {
          setMessage("Processing...");

          if (doc?.data?.results?.signer?.isSigned === 1 && !states?.email) {
            setMessage("Document already signed");
            return;
          } else if (
            doc?.data?.results?.signer?.isSigned === 1 &&
            states?.email
          ) {
            
            navigation(
              `${routes.CAN_DOCUMENT.DOC_PREVIEW_SINNER_CAN}/${id}/${states?.email}
              `
            );
            return;
          }
          setDocInfo(doc.data.results);
          if (!doc.data.results.metadataUrl) {
            dispatch(ShowAlert("Document not found..!!"));
          } else {
            await processMetadata(doc.data.results.metadataUrl);
            setS3MetadataUrl(doc.data.results.metadataUrl);

          // docInfo.isPasswordRequired      
          if(doc.data.results.is_password_required){
            setdecryptionPopup(true);
          }else{
            setDecPassword(process.env.REACT_APP_DEFAULT_PASSWORD)
            // setdecryptionPopup(false);
            // onPassSubmit()  
          }
          }
          setMessage(null);
          setShowLoader({ show: false, message: "Processing...123" });
        }else if (doc.data.code === 202) {
          dispatch(ShowAlert(doc?.data?.message, "error"));
          navigation(`${routes.DASHBOARD}`);
        } else {
          dispatch(ShowAlert(doc?.data?.message, "error"));
          setMessage("Error in loading page..!!");
        }
      } catch (error) {
        console.log("come to error", error);
        
        dispatch(ShowAlert("Something went wrong..!! ", "error"));
      } finally {
        setShowLoader({ show: false, message: "Processing..." });
      }
    })();
  }, [dispatch, id]);

  const processMetadata = async (metadataUrl) => {
    const docData = await getFile(metadataUrl);
    setMetadataString(docData);
  };

  const onPassSubmit = async () => {
    setPassError(null);
    if (decPassword.length < 6) {
      setPassError("Password is required");
      return;
    }

    // decrypt string
    try {
      const bytes = CryptoJS.AES.decrypt(metadataString.blob, decPassword);
      const originalText = bytes.toString(CryptoJS.enc.Utf8);
      const jsonMeta = flatted.parse(originalText);
      setdecryptionPopup(false);
      dispatch(setReceiverMetadata(jsonMeta));
    } catch (error) {
      setPassError("Password is Incorrect");
    }
  };

  useEffect(() => {
    if (receiverMetadata === null) {
      return;
    }
    const signer = receiverMetadata.signers.filter(
      (e) => user.email.toLowerCase() === e.email.toLowerCase()
    );
    if (signer.length === 0) {
      setMessage("Document is expired or does not belongs to you");
      return;
    }
    if (signer[0].hasSigned) {
      setMessage("This Document has been already signed");
      return;
    }
    setSigner(signer[0]);
    setMessage(null);
  }, [receiverMetadata]);

  const handleNext = () => {
    if (count >= receiverMetadata.signers[0].fields.length || count < 0) {
      setCount(0);
      return;
    }

    const scrollPosition = receiverMetadata.signers[0].fields[count];
    window.scrollTo({ top: scrollPosition.positionFromParentTop - 100 });
    setCount(count + 1);
  };

  const handlePrev = () => {
    if (count < 0) {
      return;
    }
    

    if (count > receiverMetadata.signers[0].fields.length - 1) {
      setCount(receiverMetadata.signers[0].fields.length - 1);
      return;
    }
    const scrollPosition = receiverMetadata.signers[0].fields[count];
    window.scrollTo({ top: scrollPosition.positionFromParentTop - 100 });
    setCount(count - 1);
  };

  const handleFormFinish = async () => {
    const error = [];
    signer.fields.forEach((e) => {
      if (e.isRequired) {
        if (!e.isFilled) {
          e.errorMessage = "Highlighted fields is required";
          error.push(e);
        } else {
          e.errorMessage = null;
        }
      }
    });
    dispatch(setReceiverMetadata({ ...receiverMetadata }));
    if (error.length > 0) {
      // toast.error(error[0].errorMessage);
      return;
    }

    await goWithS3Flow();
  };

  const goWithS3Flow = async () => {
    try {
      setSuccessModalPopup(true);
      if (decPassword.length < 5) {
        return;
      }
      signer.hasSigned = true;

      const blobString = flatted.stringify(receiverMetadata);
      metadataString.blobHash = EDH.SHA1_hash(blobString);
      metadataString.isSigned = true;
      const ciphertext = CryptoJS.AES.encrypt(
        blobString,
        decPassword
      ).toString();

      metadataString.blob = ciphertext;

      const jsonFile = new Blob([JSON.stringify(metadataString)], {
        type: "application/json",
      });
      jsonFile.name = docInfo.signer.metadataUrl.replace(/^.*[\\\/]/, "");
      await uploadFile(jsonFile, docInfo.signer.metadataUrl.split(".com/")[1]);
      // console.log(metadataResult);
      const query = `recruiter_id=${user?.recruiter_id || ""}&email=${
        user.email || ""
      }&uuid=${id || ""}&candidate_id=${user?.candidate_id || ""}`;
      let updateToServer;
      let signerData = [];
      receiverMetadata.signers.map((element, index)=>{
        if(element.fields.length > 0){
          signerData[index] = element;
          signerData[index].fields.map((elementData, eleIndex)=>{
            let currentEle = elementData;
            delete currentEle.signer;
            signerData[index]['fields'][eleIndex] = currentEle;
          })
        }
      })
      const pdfUrl = await handleUploadS3PDF(); 
      if (states?.email) {
        updateToServer = await UpdateGuestEDoc(query, {signers:signerData, location: pdfUrl.location});
      } else {
        updateToServer = await UpdateEDoc(query, {signers:signerData, location: pdfUrl.location});
      }
      if (updateToServer?.data?.code === 200) {
        dispatch(ShowAlert(updateToServer?.data?.message, "success"));
        setTimeout(() => {
          setSuccessModalPopup(false);
          navigation(
            user?.type === "recruitment"
              ? routes.ALL_DOCUMENTS
              : routes.CANDIDATE_DOC_LIST
          );
        }, 2000);
      } else {
        dispatch(ShowAlert(updateToServer?.data?.message, "error"));
      }
    } catch (error) {
      console.error(error);
      setSuccessModalPopup(false);
    }
  };

  const changeInNumber = (field) => {
    if (
      field?.height &&
      typeof field?.height === "string" &&
      field?.height?.includes("px")
    ) {
      return Number(
        field?.height.split("px")[0] || field?.height.split("PX")[0]
      );
    }
    return field?.height;
  };


  function dataURLtoFile(dataurl, filename) {
    const raw = atob(dataurl.split(",")[1]);
    const rawLength = raw.length;

    const array = new Uint8Array(new ArrayBuffer(rawLength));
    for (let i = 0; i < rawLength; i += 1) {
      array[i] = raw.charCodeAt(i);
    }

    return array;
  }

  const handleUploadS3PDF = async () => {
    const pdfDoc = await PDFDocument.load(receiverMetadata.arrayBuffer);
    const metadata = receiverMetadata;
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

    const pages = pdfDoc.getPages();

    metadata.signers.forEach((element) => {
      if(element.hasSigned || element.isSigned || element.email === signer.email){
        element.fields.forEach(async (field) => {
          if (
            field.fieldType === FieldTypes.TEXT_INPUT ||
            field.fieldType === FieldTypes.DATES
          ) {
            pages[field?.page - 1].drawText(field.textValue, {
              x:
                field.positionFromParentLeft -
                (field.maxWidth - field.pageWidth) / 2,
              y: field.pagePositionY - changeInNumber(field) / 2,
              width: field.width,
              height: changeInNumber(field),
              size: 16,
              font: helveticaFont,
            });
          }
  
          if (field.fieldType === FieldTypes.CHECKBOX) {
            const form = pdfDoc.getForm();
            const checkBox = form.createCheckBox(
              `some.checkBox.checked${field.id}`
            );
  
            checkBox.addToPage(pages[field?.page - 1], {
              x:
                field.positionFromParentLeft -
                (field.maxWidth - field.pageWidth) / 2,
              y: field.pagePositionY - changeInNumber(field),
              width: field.width,
              height: changeInNumber(field),
              textColor: rgb(0, 0, 1),
              borderColor: rgb(0, 0, 1),
              borderWidth: 2,
            });
            checkBox.check();
            checkBox.enableReadOnly();
          }
  
          if (
            field.fieldType === FieldTypes.INITIALS ||
            field.fieldType === FieldTypes.SIGNATURE
          ) {
            const data = dataURLtoFile(field.base64Image, "hello.png");
            const jpgImage = await pdfDoc.embedPng(data);
            pages[field?.page - 1].drawImage(jpgImage, {
              x:
                field.positionFromParentLeft -
                (field.maxWidth - field.pageWidth) / 2,
              y: field.pagePositionY - changeInNumber(field),
              width: field.width,
              height: changeInNumber(field),
            });
          }
        });
      }
    });


    docInfo.signers.forEach((element) => {
      if(element.isSigned){
        
        element.fields.forEach(async (field) => {
          if (
            (field.fieldType === FieldTypes.TEXT_INPUT ||
            field.fieldType === FieldTypes.DATES) && field.textValue
          ) {
            pages[field?.page - 1].drawText(field.textValue, {
              x:
                field.positionFromParentLeft -
                (field.maxWidth - field.pageWidth) / 2,
              y: field.pagePositionY - changeInNumber(field) / 2,
              width: field.width,
              height: changeInNumber(field),
              size: 16,
              font: helveticaFont,
            });
          }
  
          if (field.fieldType === FieldTypes.CHECKBOX) {
            const form = pdfDoc.getForm();
            const checkBox = form.createCheckBox(
              `some.checkBox.checked${field.id}`
            );
  
            checkBox.addToPage(pages[field?.page - 1], {
              x:
                field.positionFromParentLeft -
                (field.maxWidth - field.pageWidth) / 2,
              y: field.pagePositionY - changeInNumber(field),
              width: field.width,
              height: changeInNumber(field),
              textColor: rgb(0, 0, 1),
              borderColor: rgb(0, 0, 1),
              borderWidth: 2,
            });
            checkBox.check();
            checkBox.enableReadOnly();
          }
  
          if (
           ( field.fieldType === FieldTypes.INITIALS ||
            field.fieldType === FieldTypes.SIGNATURE) && field.base64Image
          ) {

            const data = dataURLtoFile(field.base64Image, "hello.png");
            const jpgImage = await pdfDoc.embedPng(data);
            pages[field?.page - 1].drawImage(jpgImage, {
              x:
                field.positionFromParentLeft -
                (field.maxWidth - field.pageWidth) / 2,
              y: field.pagePositionY - changeInNumber(field),
              width: field.width,
              height: changeInNumber(field),
            });
          }
        });
      }
    });
    let page = pdfDoc.addPage();
    const { width, height } = page.getSize();
    page.drawText("Signing Documents", {
      x: 210,
      y: height - 4 * 15,
      size: 20,
      font: helveticaFont,
    });

    page.drawText(`Document title: `, {
      x: 50,
      y: height - 9 * 16,
      size: 16,
    });

    page.drawText(`${metadata.title}`, {
      x: 200,
      y: height - 9 * 16,
      size: 16,
      color: rgb(0.45, 0.45, 0.45),
    });

    page.drawText(`Shared By`, {
      x: 50,
      y: height - 11 * 16,
      size: 18,
    });

    page.drawText(`Name`, {
      x: 50,
      y: height - 13 * 16,
      size: 14,
    });

    page.drawText(`${metadata.sender.name}`, {
      x: 200,
      y: height - 13 * 16,
      size: 14,
      color: rgb(0.45, 0.45, 0.45),
    });

    page.drawText(`Email`, {
      x: 50,
      y: height - 14 * 16,
      size: 14,
    });

    page.drawText(`${metadata.sender.email}`, {
      x: 200,
      y: height - 14 * 16,
      size: 14,
      color: rgb(0.45, 0.45, 0.45),
    });

    let signedCodumentHeight = 16;
    let documentHeight;
    let unSignedEmails = [];
    if (docInfo && docInfo.signers && docInfo.signers.length) {
      // if (showAlreadySign) {
        page.drawText("Already Signed documents", {
          x: 50,
          y: height - 16 * 16,
          size: 18,
        });
      // }
      let signedEmails = [];
      docInfo.signers.map((item) => {
        if(!signedEmails.includes(item.signerEmail)){
          
          if (item?.isSigned || item.signerEmail === signer.email) {
            signedCodumentHeight = signedCodumentHeight + 2;
  
            documentHeight = height - signedCodumentHeight * 16;
            if (documentHeight > height || documentHeight < 0) {
              page = pdfDoc.addPage();
              signedCodumentHeight = 4;
            }
  
            page.drawText("Name", {
              x: 50,
              y: height - signedCodumentHeight * 16,
              size: 14,
            });
            page.drawText(item.name, {
              x: 200,
              y: height - signedCodumentHeight * 16,
              size: 14,
              color: rgb(0.45, 0.45, 0.45),
            });
            signedCodumentHeight = signedCodumentHeight + 1;
  
            documentHeight = height - signedCodumentHeight * 16;
            if (documentHeight > height || documentHeight < 0) {
              page = pdfDoc.addPage();
              signedCodumentHeight = 4;
            }
  
            page.drawText("Email", {
              x: 50,
              y: height - signedCodumentHeight * 16,
              size: 14,
            });
            page.drawText(item.signerEmail, {
              x: 200,
              y: height - signedCodumentHeight * 16,
              size: 14,
              color: rgb(0.45, 0.45, 0.45),
            });
          }
          else{
            unSignedEmails.push(item.signerEmail);
          }
           signedEmails.push(item.signerEmail);
        }
      });

      signedCodumentHeight = signedCodumentHeight + 2;

      documentHeight = height - signedCodumentHeight * 16;
      if (documentHeight > height || documentHeight < 0) {
        page = pdfDoc.addPage();
        signedCodumentHeight = 4;
      }

    if (unSignedEmails && unSignedEmails.length) {
      page.drawText("Waiting For Signature", {
        x: 50,
        y: height - signedCodumentHeight * 16,
        size: 18,
      });

      docInfo.signers.map((item, key) => {
        if(!item.isSigned && item.signerEmail !== signer.email){

        signedCodumentHeight = signedCodumentHeight + 2;

        documentHeight = height - signedCodumentHeight * 16;
        if (documentHeight > height || documentHeight < 0) {
          page = pdfDoc.addPage();
          signedCodumentHeight = 4;
        }

        page.drawText("Name", {
          x: 50,
          y: height - signedCodumentHeight * 16,
          size: 14,
        });
        page.drawText(item.name, {
          x: 200,
          y: height - signedCodumentHeight * 16,
          size: 14,
          color: rgb(0.45, 0.45, 0.45),
        });
        signedCodumentHeight = signedCodumentHeight + 1;

        documentHeight = height - signedCodumentHeight * 16;
        if (documentHeight > height || documentHeight < 0) {
          page = pdfDoc.addPage();
          signedCodumentHeight = 4;
        }

        page.drawText("Email", {
          x: 50,
          y: height - signedCodumentHeight * 16,
          size: 14,
        });
        page.drawText(item.signerEmail, {
          x: 200,
          y: height - signedCodumentHeight * 16,
          size: 14,
          color: rgb(0.45, 0.45, 0.45),
        });
        }
      });
      }
    }

    const pdfBytes = await pdfDoc.save();
    const pdfFilePath = `media/documentSigning/pdf/${docInfo?.id}/${docInfo?.signer?.id}/${metadata?.title.toLowerCase().replaceAll(/ /g,"_")}document.pdf`
    const uploadResult = await uploadFile(pdfBytes, pdfFilePath);
    return uploadResult;
  };

const hideAskPassword = () => {
  navigation(
        user?.type === "recruitment"
          ? routes.ALL_DOCUMENTS
          : routes.CANDIDATE_DOC_LIST
      );
  setdecryptionPopup(false);
};
  return (
    <>
      {/* <DashboardHeader /> */}
      <div className="header_box"></div>
      {/* <Helmet>
        <title>{docInfo ? docInfo.title : "Document loading..."}</title>
      </Helmet> */}
      {/* <ToastContainer /> */}
      <div className="start_area">
        <div className="sticky-bar">
          <SpinnerLoader
            isOpen={showLoader.show}
            message={showLoader.message}
          />
          {message
            ? !decryptionPopup &&
              !successModalPopup && <MessageBox message={message} />
            : receiverMetadata &&
              !decryptionPopup &&
              docInfo && (
                <div className="row mx-0">
                  <div className="col-md-6 mb-3 mb-md-0">
                    <h5>
                      Please fill in {signer?.fields?.length || ""} required
                      fields.
                    </h5>
                  </div>
                  <div className="col-md-6">
                    <div
                      className="d-flex justify-content-md-end flex-wrap "
                      style={{ gap: "8px" }}
                    >
                      <button
                        className="nav-link cursor-pointer"
                        // style={{ cursor: "pointer" }}
                        onClick={handlePrev}
                      >
                        Previous
                      </button>
                      <button
                        className="nav-link cursor-pointer"
                        // style={{ cursor: "pointer" }}
                        onClick={handleNext}
                      >
                        Next
                      </button>
                      <button
                        className="nav-link cursor-pointer"
                        // style={{ cursor: "pointer" }}
                        onClick={handleFormFinish}
                      >
                        Finish
                      </button>
                    </div>
                  </div>
                </div>
              )}
        </div>
      </div>
      {s3MetadataUrl && (
        <AskPassword
          decryptionPopup={decryptionPopup}
          onPassSubmit={onPassSubmit}
          passError={passError}
          setDecPassword={setDecPassword}
          hideAskPassword={hideAskPassword}
        />
      )}

      {!decryptionPopup && docInfo && (
        <div className="receiver-area" style={{ marginTop: "40px" }}>
          <div className="container">
            {receiverMetadata && message === null && (
              <SignDocContainer receiverMetadata={receiverMetadata} />
            )}
          </div>
        </div>
      )}

      <PopupModal
        closeOnEsc={false}
        closeOnOverlayClick={false}
        minWidth={200}
        onClose={() => {}}
        open={successModalPopup}
        showCloseIcon={false}
      >
        <div className="margin_box popup text-center">
          <img
            src={LoaderGif}
            alt="loader"
            style={{ margin: "auto", width: 50, height: 50 }}
          />
        </div>
      </PopupModal>

      <Modal
        center
        closeOnEsc={false}
        closeOnOverlayClick={false}
        onClose={() => setOpenNoEtheriumPopup(false)}
        open={openNoEtheriumPopup}
      >
        <div className="optionContainer iconPopups" style={{ paddingTop: 30 }}>
          <div className="margin_box popup">
            Please install Etherium client in browser first
          </div>
        </div>
      </Modal>
    </>
  );
}

export default DocSign;
